<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
		></pui-datatable>
		<mtipocanalcmnc-modals
			:modelName="modelName"
		></mtipocanalcmnc-modals>
	</div>
</template>

<script>
import mtipocanalcmncActions from './MtipocanalcmncActions';
import mtipocanalcmncModals from './MtipocanalcmncModals.vue';

export default {
	name: 'mtipocanalcmnc-grid',
	components: {
		'mtipocanalcmnc-modals': mtipocanalcmncModals
	},
	data() {
		return {
			modelName: 'mtipocanalcmnc',
			actions: mtipocanalcmncActions.gridactions,
			modelColumnDefs: {
			}
		}
	}
}
</script>
